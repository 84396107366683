/*
 Template Name: Foxia - Responsive Bootstrap 4 Admin Dashboard
 Author: Themesbrand
 File: Dashboard js
 */

!function ($) {
    "use strict";

    var Dashboard = function () {
    };

    //creates Stacked chart
    Dashboard.prototype.createStackedChart = function (element, data, xkey, ykeys, labels, lineColors) {
        Morris.Bar({
            element: element,
            data: data,
            xkey: xkey,
            ykeys: ykeys,
            stacked: true,
            labels: labels,
            hideHover: 'auto',
            resize: true, //defaulted to true
            gridLineColor: '#eeeeee',
            barColors: lineColors
        });
    },

        //creates Donut chart
        Dashboard.prototype.createDonutChart = function (element, data, colors) {
            Morris.Donut({
                element: element,
                data: data,
                resize: true,
                colors: colors,
            });
        },

        // // pie
        // $('.peity-pie').each(function () {
        //     $(this).peity("pie", $(this).data());
        // });

        // //donut
        // $('.peity-donut').each(function () {
        //     $(this).peity("donut", $(this).data());
        // });

        // // line
        // $('.peity-line').each(function () {
        //     $(this).peity("line", $(this).data());
        // });

        $.Dashboard = new Dashboard, $.Dashboard.Constructor = Dashboard
}(window.jQuery);

//initializing
    // function ($) {
    //     "use strict";
    //     $.Dashboard.init();
    // }(window.jQuery);
